import { Form, Button, Card, Row, Col, Badge } from "react-bootstrap";
import { loc } from "../shared/utils-service";
import firestore from "../shared/firebase-connect";
import { NotifContext } from "../shared/notif-provider";
import { useContext, useState, useEffect } from "react";

export default function DetailItemEdit({ section, category }) {
  const excludedkeys = ["categoryName", "isActive", "docId"];
  // eslint-disable-next-line
  const [state, dispatch] = useContext(NotifContext);
  const [detail, setDetail] = useState([]);
  const [deleted, setDeleted] = useState(null);

  useEffect(() => {
    setDetail([]);
    if (section?.value && category?.value) {
      let temp = [];
      const dataRef = firestore.collection("data");
      const locRef = dataRef.doc(loc.city).collection(loc.ward);
      locRef
        .doc(section.value)
        .collection("dataList")
        .where("categoryName", "==", category.value)
        .where("isActive", "==", true)
        .get()
        .then((data) => {
          data.forEach((doc) => {
            const item = doc.data();
            item["docId"] = doc.id;
            temp.push(item);
          });
          setDetail(temp);
        });
    }
  }, [section, category, deleted]);

  const deleteItem = (item) => {
    const dataRef = firestore.collection("data");
    const locRef = dataRef.doc(loc.city).collection(loc.ward);
    locRef
      .doc(section.value)
      .collection("dataList")
      .doc(item.docId)
      .set(
        {
          isActive: false,
        },
        { merge: true }
      )
      .then(() => {
        setDeleted(item.docId);
        dispatch({ type: "show_notification", msg: "Record deleted" });
      })
      .catch((error) => {
        dispatch({
          type: "show_notification",
          msg: "Error deleting record !!",
        });
        console.log("Error", error);
      });
  };

  const updateItem = (item) => {
    const toSet = { ...item };
    delete toSet["docId"];
    const dataRef = firestore.collection("data");
    const locRef = dataRef.doc(loc.city).collection(loc.ward);
    locRef
      .doc(section.value)
      .collection("dataList")
      .doc(item.docId)
      .set({ ...toSet }, { merge: true })
      .then(() => {
        dispatch({ type: "show_notification", msg: "Record updated" });
      })
      .catch((error) => {
        dispatch({
          type: "show_notification",
          msg: "Error updating record !!",
        });
        console.log("Error", error);
      });
  };

  const formUpdate = (item, e, key) => {
    item[key] = e.target.value;
  };

  const createEditabelDate = (item, key) => {
    return `${new Date(item[key].seconds * 1000).toLocaleDateString(
      "en-IN"
    )} ${new Date(item[key].seconds * 1000).toLocaleTimeString("en-IN")}`;
  };

  const sortedKeys = (item) => {
    return Object.keys(item).sort();
  };

  return (
    <>
      {detail.map((row, idx) => {
        return (
          <Card key={idx} className="mb-4 text-muted app-card">
            <Card.Body>
            <Badge pill variant="secondary" className="mb-2">
              {idx+1}
            </Badge>{' '}
              <Form.Group as={Row} xs={1} sm={2} md={3}>
                {sortedKeys(row).map((key, idx) => {
                  return (
                    excludedkeys.indexOf(key) === -1 && (
                      <Col key={idx} className="pb-3">
                        <Form.Label>{key}</Form.Label>
                        {key !== "date" ? (
                          <Form.Control
                            type="text"
                            defaultValue={row[key]}
                            onChange={(e) => formUpdate(row, e, key)}
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            defaultValue={createEditabelDate(row, key)}
                            onChange={(e) => formUpdate(row, e, key)}
                          />
                        )}
                      </Col>
                    )
                  );
                })}
              </Form.Group>
              <Button variant="primary float-right ml-2" onClick={() => updateItem(row)}>
                <i className="bi bi-upload"></i>
                {" "}Save
              </Button>{" "}
              <Button variant="danger float-right" onClick={() => deleteItem(row)}>
                <i className="bi bi-trash"></i>
                {" "}Delete
              </Button>{" "}
            </Card.Body>
          </Card>
        );
      })}
    </>
  );
}
