import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function SectionItem({section}) {
  let history = useHistory();

  const handleOnSelect = () => {
    history.push(`/${section.docId}`);
  };

  return (
    <>
        <Card className="mb-2 app-card app-section">
          <Card.Body
            className="p-4 text-center justify-content-center align-items-center d-flex"
            onClick={handleOnSelect}
          >
            <h3>{section.display}</h3>
          </Card.Body>
        </Card>
    </>
  );
}
