import firestore from "./shared/firebase-connect";
import { useState, useEffect, useContext } from "react";
import React from "react";
import "./index.scss";
import Section from "./pages/Section";
import Category from "./pages/Category";
import Detail from "./pages/Detail";
import AppNav from "./components/AppNav";
import AppFooter from "./components/AppFooter";
import AppActionBar from "./components/AppActionBar";
import AppDisclaimer from "./components/AppDisclaimer";
import { Container } from "react-bootstrap";
import { Switch, Route, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import { Toast } from "react-bootstrap";
import { NotifContext } from "./shared/notif-provider";

export default function App() {
  const [topDisplay, setTopDisplay] = useState(null);
  const [bottomDisplay, setBottomDisplay] = useState(null);
  let location = useLocation();
  const [ state, dispatch ] = useContext(NotifContext);

  useEffect(() => {
    const masterRef = firestore.collection("master_ref");
    masterRef
      .where("masterType", "==", "displaySection")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setBottomDisplay(doc.data().bottomDisplay);
          if (location.pathname !== "/") {
            setTopDisplay(null);
          } else {
            setTopDisplay(doc.data().topDisplay);
          }
        });
      });
  }, [location]);

  return (
    <>
          <AppNav></AppNav>
          <AppActionBar text={topDisplay}></AppActionBar>
          <Container className="pb-4 app-container">
            <Switch>
              <Route path="/login" component={Login}></Route>
              <Route path="/admin" component={Admin}></Route>
              <Route path="/:section/:category" component={Detail}></Route>
              <Route path="/:section" component={Category}></Route>
              <Route path="/" component={Section}></Route>
            </Switch>
          </Container>
          <AppDisclaimer text={bottomDisplay}></AppDisclaimer>
          <AppFooter></AppFooter>
            <Toast
              onClose={() => dispatch({ type: "hide_notification" })}
              show={state.isShowNotif}
              delay={5000}
              autohide
              className="app-notif"
            >
              <Toast.Header>
                <strong className="mr-auto">Notification</strong>
              </Toast.Header>
              <Toast.Body>
                {state.msg}
              </Toast.Body>
            </Toast>
    </>
  );
}
