export const reducer = (state, action) => {
  switch (action.type) {
    case "show_notification":
      return {
        ...state,
        msg: action.msg || null,
        isShowNotif: true,
      };
    case "hide_notification":
      return {
        ...state,
        msg: null,
        isShowNotif: false,
      };
    case "toggle_add_item":
      return {
        ...state,
        isAddItemOpen: !state.isAddItemOpen,
      };

    default:
      return state;
  }
};

export const initialState = {
  isShowNotif: false,
  msg: null,
  isAddItemOpen: false,
};
