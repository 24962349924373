import {
  Tab,
  Card,
  ProgressBar,
  Row,
  Col,
  Tabs,
  Form,
  Button,
} from "react-bootstrap";
import { UserContext } from "../shared/user-provider";
import { Link } from "react-router-dom";
import { signInWithGoogle } from "../shared/firebase-auth";
import firestore from "../shared/firebase-connect";
import { useState, useEffect, useContext } from "react";
import { loc } from "../shared/utils-service";
import DetailItemEdit from "../components/DetailItemEdit";
import DetailItemAdd from "../components/DetailItemAdd";
import { NotifContext } from "../shared/notif-provider";

export default function Admin() {
  const { user } = useContext(UserContext);
  const [userDetail, setUserDetail] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [sections, setSections] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  // eslint-disable-next-line
  const [state, dispatch] = useContext(NotifContext);

  useEffect(() => {
    if (userDetail?.role) {
      const masterRef = firestore.collection("master_ref");
      masterRef
        .where("masterType", "==", "roles")
        .where("role", "==", userDetail?.role)
        .get()
        .then((data) => {
          data.forEach((doc) => {
            setPermissions(doc.data());
          });
        });
    }
  }, [userDetail]);

  useEffect(() => {
    if (user?.email) {
      const dataRef = firestore.collection("users");
      dataRef
        .where("emailId", "==", user.email)
        .get()
        .then((data) => {
          data.forEach((doc) => {
            setUserDetail(doc.data());
          });
        });
    }
  }, [user]);

  useEffect(() => {
    let temp = [];
    const masterRef = firestore.collection("master_ref");
    masterRef
      .where("masterType", "==", "section")
      .where("isActive", "==", true)
      .get()
      .then((data) => {
        data.forEach((doc) => {
          temp.push(doc.data());
        });
        //Sort them by display order
        temp.sort((a, b) => {
          return a.displayOrder - b.displayOrder;
        });
        setSections(temp);
      });
  }, [permissions]);

  useEffect(() => {
    if (selectedSection?.value) {
      let temp = [];
      const dataRef = firestore.collection("data");
      const locRef = dataRef.doc(loc.city).collection(loc.ward);
      locRef
        .doc(selectedSection.value)
        .collection("categories")
        .where("isActive", "==", true)
        .get()
        .then((data) => {
          data.forEach((doc) => {
            temp.push(doc.data());
          });
          setCategories(temp);
        });
    }
  }, [selectedSection]);

  const selectSection = (section) => {
    setCategories([]);
    setSelectedCategory(null);
    setSelectedSection(section);
  };

  const selectCategory = (category) => {
    setSelectedCategory(category);
  };

  const addItem = () => {
    dispatch({ type: "toggle_add_item" });
  }

  const cardBody = () => {
    if (!user) {
      return <ProgressBar animated now={100} />;
    }
    if (user?.email) {
      if (userDetail && permissions) {
        return (
          <Tabs defaultActiveKey={permissions.menus[0]}>
            {permissions.menus?.map((menu, idx) => {
              return (
                <Tab className="p-3" key={idx} eventKey={menu} title={menu}>
                  <Form>
                    <Form.Group as={Row}>
                      <Col xs={12} sm={5} md={4}>
                        <Form.Label>Section</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            selectSection({ value: e.target.value })
                          }
                        >
                          <option></option>
                          {sections.map((section, idx) => (
                            <option key={idx} value={section.docId}>
                              {section.display}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col xs={12} sm={5} md={4}>
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            selectCategory({ value: e.target.value })
                          }
                        >
                          <option></option>
                          {categories.map((category, idx) => (
                            <option key={idx} value={category.categoryName}>
                              {category.categoryName}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col xs={12} sm={2} md={4}>
                        <div className="mt-2 d-none d-sm-block">{" "}</div>
                        <Button variant="success" className="mt-4 gap-1" disabled={!selectedCategory} onClick={() => addItem()}>
                          Add Item
                        </Button>{" "}
                      </Col>
                    </Form.Group>
                  </Form>
                  <DetailItemAdd
                    section={selectedSection}
                    category={selectedCategory}
                  ></DetailItemAdd>
                  <DetailItemEdit
                    section={selectedSection}
                    category={selectedCategory}
                  ></DetailItemEdit>
                </Tab>
              );
            })}
          </Tabs>
        );
      }
    } else {
      return (
        <>
          Please{" "}
          <Link to="/admin" onClick={signInWithGoogle}>
            login
          </Link>{" "}
          to continue.
        </>
      );
    }
  };

  return (
    <Card className="app-admin-page">
      <Card.Header>
        {user?.displayName && (
          <div className="d-flex justify-content-between">
            <span>{user?.displayName}</span>
            <span>{userDetail?.emailId}</span>
            <span>{userDetail?.contactNum}</span>
            <span>{userDetail?.role}</span>
          </div>
        )}
      </Card.Header>
      <div className="p-3">{cardBody()}</div>
    </Card>
  );
}
