import {createContext, useReducer} from "react";
import { reducer, initialState } from "./reducer";

export const NotifContext = createContext({
  state: initialState,
  dispatch: () => null
})

export const NotifProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <NotifContext.Provider value={[ state, dispatch ]}>
    	{ children }
    </NotifContext.Provider>
  )
}
