import { useState, useEffect } from "react";
import firestore from "../shared/firebase-connect";
import { Breadcrumb, Row, Col } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import CategoryItem from "../components/CategoryItem";
import {loc} from '../shared/utils-service';

export default function Category() {
  const history = useHistory();
  let { section } = useParams();
  const [categories, setCategories] = useState([]);
  const [displayName, setDisplayName] = useState(null);

  useEffect(() => {
      let temp = [];
      const dataRef = firestore.collection("data");
      const locRef = dataRef.doc(loc.city).collection(loc.ward);
      locRef
        .doc(section)
        .collection("categories")
        .where("isActive", "==", true)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            temp.push(doc.data());
          });
          setCategories(temp);
        });

      locRef.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => { 
          if(doc.data().section === section){
            setDisplayName(doc.data().displayName);
          }
        });
      });

  }, [section]);

  const goHome = () => {
    history.push('/');
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={goHome}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>{displayName || section}</Breadcrumb.Item>
      </Breadcrumb>
      <Row xs={1} sm={2} md={3} lg={4}>
        {categories.map((category, idx) => (
          <Col key={idx} className="mb-4">
            <CategoryItem category={category} section={section}></CategoryItem>
          </Col>
        ))}
      </Row>
      {categories.length === 0 && (
        <div className="text-center">No data found.</div>
      )}
    </>
  );
}
