import { Navbar, Nav, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { logOut } from "../shared/firebase-auth";
import { UserContext } from "../shared/user-provider";
import {useContext} from "react";

export default function AppNav() {
  const { user } = useContext(UserContext);

  const navLinks = () => {
    if (!user) {
      return <Spinner animation="grow" variant="light" />;
    }
    if (user?.email) {
      return (
        <>
          <Navbar.Text className="color-gray">{user.displayName}</Navbar.Text>
          <Nav.Link className="color-white app-link" onClick={logOut}>
            Logout
          </Nav.Link>{" "}
        </>
      );
    }
  };

  return (
    <Navbar collapseOnSelect expand="md" bg="primary" variant="dark" sticky="top">
      <Link className="navbar-brand mr-auto" to="/">
        Get a Help
      </Link>
      {navLinks()}
    </Navbar>
  );
}
