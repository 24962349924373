import { Form, Card, Row, Col, Button, Collapse } from "react-bootstrap";
import { loc } from "../shared/utils-service";
import firestore from "../shared/firebase-connect";
import { NotifContext } from "../shared/notif-provider";
import { useContext, useState, useEffect } from "react";

export default function DetailItemAdd({ section, category }) {
  const [state, dispatch] = useContext(NotifContext);
  const [template, setTemplate] = useState(null);
  const [form, setForm] = useState(null);

  useEffect(() => {
    setTemplate(null);
    setForm(null);
    if (section?.value && category?.value) {
      const dataRef = firestore.collection("master_ref");
      dataRef
        .where("masterType", "==", "template")
        .where("categoryName", "==", category.value)
        .where("section", "==", section.value)
        .get()
        .then((data) => {
          if (data.empty) {
            dataRef
              .where("masterType", "==", "template")
              .where("categoryName", "==", "default")
              .where("section", "==", "default")
              .get()
              .then((data) => {
                data.forEach((doc) => {
                  setTemplate(doc.data().attributes);
                  generateForm(doc.data().attributes);
                });
              });
          } else {
            data.forEach((doc) => {
              setTemplate(doc.data().attributes);
              generateForm(doc.data().attributes);
            });
          }
        });
    }
  }, [section, category]);

  const addItem = () => {
    const dataRef = firestore.collection("data");
    const locRef = dataRef.doc(loc.city).collection(loc.ward);
    locRef
      .doc(section.value)
      .collection("dataList")
      .add({
        ...form,
        isActive: true,
        categoryName: category.value,
      })
      .then((docRef) => {
        console.log("Success", docRef);
        dispatch({ type: "show_notification", msg: "Record added" });
        setForm(null);
        generateForm(form);
      })
      .catch((error) => {
        dispatch({
          type: "show_notification",
          msg: "Error adding record !!",
        });
        console.log("Error", error);
      });
  };

  const formUpdate = (form, e, key) => {
    form[key] = e.target.value;
  };

  const generateForm = (form) => {
    Object.keys(form).map((key) => (form[key] = ""));
    setForm(form);
  };

  return (
    <Collapse in={state.isAddItemOpen}>
      <div>
        <Card className="mb-4 text-muted app-card">
          <Card.Body>
            <h6 className="mb-3">Add New Item</h6>
            <Form.Group as={Row} xs={1} sm={2} md={3}>
              {form &&
                Object.keys(form)
                  .sort()
                  .map((key, idx) => {
                    return (
                      <Col key={idx} className="pb-3">
                        <Form.Label>{template[key]}</Form.Label>
                        <Form.Control
                          type="text"
                          defaultValue=""
                          onChange={(e) => formUpdate(form, e, key)}
                        />
                      </Col>
                    );
                  })}
            </Form.Group>
            <Button
              variant="success"
              className="float-right"
              onClick={() => addItem()}
            >
              Save
            </Button>{" "}
          </Card.Body>
        </Card>
      </div>
    </Collapse>
  );
}
