import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function CategoryItem({ category, section }) {
  let history = useHistory();

  const onCardClick = () => {
    history.push(`/${section}/${category.categoryName}`);
  }

  return (
    <Card className="app-category app-card text-center" onClick={onCardClick}>
      <Card.Body>
        <Card.Title>{category.categoryName}</Card.Title>
        <Card.Text>
          {category.desc}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
