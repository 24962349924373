import firestore from "../shared/firebase-connect";
import { useState, useEffect } from "react";
import SectionItem from "../components/SectionItem";
import { Row, Col } from "react-bootstrap";

export default function Section() {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    let temp = [];
    const masterRef = firestore.collection("master_ref");
    masterRef
      .where("masterType", "==", "section")
      .where("isActive", "==", true)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          temp.push(doc.data());
        });
        //Sort them by display order
        temp.sort((a, b) => {
          return a.displayOrder - b.displayOrder;
        });
        setSections(temp);
      });
  }, []);

  return (
    <>
      <Row xs={1} sm={2} md={3}>
        {sections.map((section, idx) => (
          <Col key={idx} className="mb-4">
            <SectionItem section={section}></SectionItem>
          </Col>
        ))}
      </Row>
      {sections.length === 0 && (
        <div className="text-center">No data found.</div>
      )}
    </>
  );
}
