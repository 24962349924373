import { useState, useEffect } from "react";
import firestore from "../shared/firebase-connect";
import { loc } from "../shared/utils-service";
import { Breadcrumb } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import DetailItem from "../components/DetailItem";

export default function Detail() {
  const history = useHistory();
  let { category, section } = useParams();
  const [detail, setDetail] = useState([]);
  const [displayName, setDisplayName] = useState(null);

  useEffect(() => {
      let temp = [];
      const dataRef = firestore.collection("data");
      const locRef = dataRef.doc(loc.city).collection(loc.ward);
      locRef
        .doc(section)
        .collection("dataList")
        .where("categoryName", "==", category)
        .where("isActive", "==", true)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            temp.push(doc.data());
        });
        //Sort them by display order
        temp.sort((a, b) => {
          return b.date?.seconds - a.date?.seconds;
        });
        setDetail(temp);
    });
    
    locRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => { 
        if(doc.data().section === section){
          setDisplayName(doc.data().displayName);
        }
      });
    });

  }, [category, section]);

  const goHome = () => {
    history.push('/');
  }

  const goBack = () => {
    history.push(`/${section}`);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={goHome}>Home</Breadcrumb.Item>
        <Breadcrumb.Item onClick={goBack}>{displayName || section}</Breadcrumb.Item>
        <Breadcrumb.Item active>{category}</Breadcrumb.Item>
      </Breadcrumb>
      {detail.map((card, idx) => (
        <DetailItem key={idx} item={card} section={section}></DetailItem>
      ))}
      {detail.length === 0 && (
        <div className="text-center">No data found.</div>
      )}
    </>
  );
}
