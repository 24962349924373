import { Card, Row, Col } from "react-bootstrap";

export default function DetailItem({ item, section }) {
  const {
    categoryName,
    date,
    storageLink,
    desc,
    displayName,
    contactName,
    contact,
    email,
    address,
    links,
    linkDisplay,
  } = item;
  return (
    <Card className="mb-2 text-muted text-center app-card">
      <Card.Body>
        <Row xs={1} sm={2} md={3}>
          {categoryName && section === 'govcirculars' && <Col className="p-2">{categoryName}</Col>}
          {date && (
            <Col className="p-2">
              {new Date(date.seconds * 1000).toLocaleDateString("en-IN")} at{" "}
              {new Date(date.seconds * 1000).toLocaleTimeString("en-IN")}
            </Col>
          )}
          {storageLink && (
            <Col className="p-2">
              <a href={storageLink} rel="noreferrer" target="_blank">
                View
              </a>
            </Col>
          )}
          {displayName && <Col className="p-2">{displayName}</Col>}
          {contactName && <Col className="p-2">{contactName}</Col>}
          {contact && (
            <Col className="p-2">
              {item.contact.split(",").map((num, index) => {
                return <a href={`tel:${num}`}>{num}</a>;
              })}
            </Col>
          )}
          {email && <Col className="p-2">{email}</Col>}
          {address && <Col className="p-2">{address}</Col>}
          {desc && <Col className="p-2">{desc}</Col>}
          {links && (
            <Col className="p-2">
              <a href={links} rel="noreferrer" target="_blank">
                {linkDisplay}
              </a>
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
}
