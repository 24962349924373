import { Button, Card, ProgressBar } from "react-bootstrap";
import { signInWithGoogle, logOut } from "../shared/firebase-auth";
import { useHistory } from "react-router-dom";
import { UserContext } from "../shared/user-provider";
import {useContext} from "react";

export default function Login() {
  const { user } = useContext(UserContext);
  let history = useHistory();

  const gotoAdmin = () => {
    history.push(`/admin`);
  };

  const cardBody = () => {
    if(!user){
      return <ProgressBar animated now={100} />
    }
    if (user?.email) {
      return (
        <>
          <Card.Text>You are successfully logged in.</Card.Text>
          <Button variant="link" onClick={gotoAdmin}>
            Go to Admin
          </Button>
          <Button variant="link" onClick={logOut}>
            Logout
          </Button>
        </>
      );
    } else {
      return (
        <Button
          variant="light"
          className="app-login"
          onClick={signInWithGoogle}
        >
          <img
            src="/google-logo.png"
            height="30"
            width="30"
            alt="google icon"
          />
          <span> Continue with Google</span>
        </Button>
      );
    }
  };

  return (
    <Card>
      <Card.Header>
        <h5>Login</h5>
      </Card.Header>
      <Card.Body>{cardBody()}</Card.Body>
    </Card>
  );
}
